<template>
    <div style="background-color: white;">
        <div class="dropzone" :class="{ 'is-dragover': dropzoneActive }" @dragenter="dragEnter"
            @dragleave.prevent="dragLeave" @dragover.prevent.stop="dragOver" @drop.prevent.stop="dropFile">
            <div class="dropzone-content">
                <button class="upload-btn" @click.stop.prevent="onClickUpload">
                    <img class="upload-btn-icon" src="@imgs/btn-upload.png" />
                    上传文件
                </button>
                <p class="info">点击上方“上传文件”或将文件拖拽到此区域</p>
                <p class="info">可批量上传文件，支持格式：jpg、pdf、word、png、jpeg</p>
            </div>
        </div>
        <input style="display: none;" ref="input" type="file" multiple :name="fileName" @change="selectFile"
            :accept="accept" />
    </div>
</template>
<script>
export default {
    props: {
        fileName: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: ''
        },
        httpRequest: {
            type: Function,
            required: true
        },
        onStart: Function,
        onProgress: Function,
        onSuccess: Function,
        onError: Function,
        beforeUpload: Function,
        onPreview: {
            type: Function,
            default: function () { }
        },
        onRemove: {
            type: Function,
            default: function () { }
        },
        // files: Array
    },
    data() {
        return {

        }
    },
    computed: {
        // filesArray: {
        //     get() {
        //         return this.files
        //     },
        //     set(val) {
        //         this.$emit('update:files', val)
        //     }
        // }
    },
    data() {
        return {
            dropzoneActive: false,
            reqs: {}
        }
    },
    methods: {
        /**
              * 处理拖拽区域的拖入事件
              * @param {Event} event - 拖入事件对象
              */
        dragEnter(event) {
        },
        /**
         * 处理拖拽区域的拖离事件
         * @param {Event} event - 拖离事件对象
         */
        dragLeave(event) {
            this.dropzoneActive = false;
        },
        /**
         * 处理拖拽区域的拖放事件
         * @param {Event} event - 拖放事件对象
         */
        dragOver(event) {
            this.dropzoneActive = true;
        },
        /**
         * 处理文件的拖放上传
         * @param {Event} event - 拖放事件对象
         */
        dropFile(event) {
            this.dropzoneActive = false;
            const files = event.dataTransfer.files;
            this.handleFiles(files);
        },
        /**
         * 处理选择文件按钮的上传事件
         * @param {Event} event - 选择文件事件对象
         */
        selectFile(event) {
            const files = event.target.files;
            this.handleFiles(files);
        },
        /**
         * 处理上传的文件列表
         * @param {FileList} files - 文件列表
         */
        handleFiles(files) {
            // for (let i = 0; i < files.length; i++) {
            //     const file = files[i];
            //     this.filesArray.push(file);
            // }
            this.uploadFiles(files)
        },
        /**
         * 下载文件
         * @param {File} file - 文件对象
         */
        downloadFile(file) {
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(url);
        },

        onClickUpload() {
            this.$refs.input.value = null;
            this.$refs.input.click();
        },

        uploadFiles(files) {
            let postFiles = Array.prototype.slice.call(files);
            if (postFiles.length === 0) { return; }
            postFiles.forEach(rawFile => {
                this.onStart(rawFile);
                this.upload(rawFile);
            });
        },
        upload(rawFile) {
            this.$refs.input.value = null;
            if (!this.beforeUpload) {
                return this.post(rawFile);
            }
            const before = this.beforeUpload(rawFile);
            if (before && before.then) {
                before.then(processedFile => {
                    const fileType = Object.prototype.toString.call(processedFile);
                    if (fileType === '[object File]' || fileType === '[object Blob]') {
                        if (fileType === '[object Blob]') {
                            processedFile = new File([processedFile], rawFile.name, {
                                type: rawFile.type
                            });
                        }
                        for (const p in rawFile) {
                            if (rawFile.hasOwnProperty(p)) {
                                processedFile[p] = rawFile[p];
                            }
                        }
                        this.post(processedFile);
                    } else {
                        this.post(rawFile);
                    }
                }, () => {
                    this.onRemove(null, rawFile);
                });
            } else if (before !== false) {
                this.post(rawFile);
            } else {
                this.onRemove(null, rawFile);
            }
        },
        abort(file) {
            const { reqs } = this;
            if (file) {
                let uid = file;
                if (file.uid) uid = file.uid;
                if (reqs[uid]) {
                    reqs[uid].abort();
                }
            } else {
                Object.keys(reqs).forEach((uid) => {
                    if (reqs[uid]) reqs[uid].abort();
                    delete reqs[uid];
                });
            }
        },
        post(rawFile) {
            const { uid } = rawFile;
            const options = {
                file: rawFile,
                filename: this.name,
                onProgress: e => {
                    this.onProgress(e, rawFile);
                },
                onSuccess: res => {
                    this.onSuccess(res, rawFile);
                    delete this.reqs[uid];
                },
                onError: err => {
                    this.onError(err, rawFile);
                    delete this.reqs[uid];
                }
            };
            const req = this.httpRequest(options);
            this.reqs[uid] = req;
            if (req && req.then) {
                req.then(options.onSuccess, options.onError);
            }
        }
    }
}

</script>
<style scoped lang="scss">
.dropzone {
    margin: 0 23px;
    width: calc(100% - 46px);
    height: calc(100% - 4px);
    border: 2px dashed #DCDCDC;

    &:hover {
        border: 2px dashed #2E6BE6;
    }



    &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .upload-btn {
            width: 266px;
            height: 35px;
            background: #2E6BE6;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.9);

            &-icon {
                width: 16px;
                height: 16px;
            }
        }

        .info {
            margin-top: 10px;
            width: 330px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 15px;
            text-align: center;
        }
    }
}

.is-dragover {
    border: 2px dashed #2E6BE6;
    background-color: rgba(32, 159, 255, .06);
}
</style>